// eslint-disable-next-line no-unused-vars
export var configuration = {
  searchableFields: ["was"],
  sortings: {
    name_asc: {
      field: "was",
      order: "asc",
    },
  },
  aggregations: {
    season: {
      title: "Jahreszeit",
      conjunction: false,
      sort: 'term',
      order: 'asc',
      size: 2,
    },
    transport: {
      title: "Anreise",
      conjunction: false,
      sort: 'term',
      order: 'asc',
      size: 10,
    },
    accommodation: {
      title: "Unterkunft",
      conjunction: false,
      sort: 'term',
      order: 'asc',
      size: 10,
    },
    activity: {
      title: "Aktivität",
      conjunction: false,
      sort: 'term',
      order: 'asc',
      size: 6,
    },
    who: {
      title: "Wer",
      conjunction: false,
      sort: 'term',
      order: 'asc',
      size: 4,
    },
    Gruppe: {
      title: "Gruppe",
      conjunction: false,
      sort: 'term',
      order: 'asc',
      size: 5,
    }
  },
};
