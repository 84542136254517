<template>
  <v-avatar class="ma-3" size="125" tile>
    <img
      v-if="!imgError"
      :src="picPath(pic)"
      :alt="`${pic} Bild`"
      @error="onImgError()"
    />
    <div v-else>{{ picPath(pic) }}</div>
  </v-avatar>
</template>

<script>
export default {
  name: "Picture",
  props: {
    pic: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imgError: false,
    };
  },
  methods: {
    picPath(str) {
      return "/static/img/" + str.toLowerCase() + ".jpg";
    },
    onImgError() {
      this.imgError = true;
    },
  },
};
</script>

<style>
</style>