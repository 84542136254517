<template>
  <v-container>
    <!-------ITEMS LIST------->
    <v-main class="pa-0">
      <v-container class="pa-0" id="main">
        <v-row>
          <v-col cols="12">
            <p class="text-h5">{{ total }} Sachen für {{ name }}</p>
          </v-col>
        </v-row>
        <!-------GRUPPEN ANZEIGE------->
        <v-row dense v-for="gru in itemsGrouped2" :key="gru.Gruppe">
          <v-col>
            <v-card elevation="4" class="sektion" color="success">
              <v-card-title class="text-uppercase text-center text-h6">{{
                gru.Gruppe
              }}</v-card-title>
              <v-card-text>
                <v-list dense>
                  <v-list-item-group color="primary">
                    <!-------SINGLE ITEMS------->

                    <v-row
                      ><v-col
                        cols="2"
                        class="pa-0 d-print-inline"
                        v-for="item in gru.items"
                        :key="item.was"
                      >
                        <v-list-item class="pa-0 for-print">
                          <v-list-item-content class="pa-0">
                            <v-list-item-title
                              class="text-caption"
                              v-text="item.was"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item> </v-col
                    ></v-row>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
var fs = require("fs"); //for testing file existence

// eslint-disable-next-line no-unused-vars
const singleItem = require("../../public/static/item.json");

export default {
  name: "New",
  // eslint-disable-next-line vue/no-unused-components
  components: {},
  props: { name: String, total: Number, items: null },
  data() {
    return { itemsGrouped2: this.items };
  },
  methods: {},
  mounted() {},
  updated() {},
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
input#myname {
  border: none;
  border-bottom: 1px solid gray;
  background: transparent;
  outline: none;
  max-width: 400px;
}
#btnToggleDrawer {
  z-index: 9999 !important;
}

.height48 {
  height: 47px !important;
}
.item {
  min-width: 187px;
}
#main {
  background: url("../../public/static/packed.jpg") no-repeat center center
    fixed !important;
  background-size: cover;
}
.v-input {
  color: black;
  caret-color: green;
}
.v-application .error--text {
  color: #b70d0d !important;
  caret-color: var(--v-error-base) !important;
}
@media print {
  .v-main {
    padding: 0 !important;
  }
  .container {
    margin: 0 !important;
  }
  * {
    transition: none !important;
  }
  .for-print {
    min-height: 24px !important;
  }
}
</style>