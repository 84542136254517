<template>
  <v-container>
    <v-fab-transition>
      <v-btn
        id="btnToggleDrawer"
        class="d-print-none"
        :key="activeFab.icon"
        :color="activeFab.color"
        elevation="12"
        fab
        small
        top
        left
        fixed
        @click="drawer = !drawer"
      >
        <v-icon>{{ activeFab.icon }}</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition v-if="newItemArray.length > 0">
      <v-btn
        id="sendNewItems"
        class="d-print-none"
        :key="activeFab.icon"
        color="green"
        elevation="12"
        fab
        small
        bottom
        right
        fixed
      >
        <v-icon>mdi-emoticon-excited-outline</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-------NAVIGATION DRAWER------->
    <v-navigation-drawer
      v-model="drawer"
      :width="this.$vuetify.breakpoint.mobile ? '100%' : '430px'"
      app
      class="d-print-none"
      color="success"
    >
      <v-sheet class="pa-4" color="info">
        <v-container pa-0 ma-0>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="9" class="text-right"
              ><v-btn
                plain
                text
                @click="reset()"
                class="text-h4 text-uppercase warning--text"
              >
                packjack
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="11" class="text-right"
              ><v-btn
                plain
                text
                @click="debugging = !debugging"
                class="text-h6"
              >
                die interaktive Packliste
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
      <!-------GRUPPEN AUSWAHL------->
      <v-card
        v-for="facet in items.data.aggregations"
        :key="facet.title"
        elevation="5"
        color="success"
        class="ma-1"
      >
        <v-card-title class="pb-1">{{ facet.title }}</v-card-title>
        <v-card-actions class="pt-0">
          <!-------FILTERKNÖPFE------->
          <v-btn-toggle v-model="filters[facet.name]" dense multiple>
            <FilterButton
              v-for="bucket in facet.buckets"
              :key="bucket.key"
              :bucket="bucket"
              :icon="icons[bucket.key]"
            ></FilterButton>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
      <v-card elevation="5" color="success" class="ma-1">
        <v-card-actions>
          <v-btn-toggle>
            <v-btn
              @click="pictures = !pictures"
              color="accent"
              class="mb-1 text-caption black--text"
            >
              <v-icon>mdi-avatar</v-icon>mit Bildern
            </v-btn>
          </v-btn-toggle>
          <v-btn
            id="print-button"
            @click="printIt()"
            color="accent"
            class="mb-1 ml-1 text-caption black--text height48"
            ><v-icon>mdi-printer</v-icon>
          </v-btn>

          <v-btn outlined color="accent" class="height48"
            ><v-switch
              v-model="showNumbers"
              class="px-1 mx-1"
              color="accent"
              inset
              label="mit Anzahl"
            ></v-switch
          ></v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="showNumbers" elevation="5" color="success" class="ma-1">
        <v-card-text>
          <v-row no-gutters>
            <v-col class="pb-1 text-body-1 black--text">
              Reisedauer (Wochen)
            </v-col>
            <v-col cols="6" class="pb-1">
              <v-slider
                :label="weeks.toString()"
                v-model="weeks"
                min="1"
                max="4"
                thumb-label
                ticks
                hide-details
                color="accent"
                append-icon="mdi-plus"
                prepend-icon="mdi-minus"
                @click:append="weeks++"
                @click:prepend="weeks--"
              ></v-slider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="py-1 text-body-1 black--text"
              >Waschen jede {{ wash }}. Woche</v-col
            >
            <v-col cols="6" class="py-1"
              ><v-slider
                :label="wash.toString()"
                v-model="wash"
                min="1"
                max="4"
                thumb-label
                ticks
                hide-details
                color="accent"
                append-icon="mdi-plus"
                prepend-icon="mdi-minus"
                @click:append="wash++"
                @click:prepend="wash--"
              ></v-slider
            ></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <!-------ITEMS LIST------->
    <v-main class="pa-0">
      <v-container class="pa-0 d-print-none" id="main">
        <v-row>
          <v-col cols="12">
            <p class="text-h3">
              {{ items.pagination.total }} Sachen für
              <input
                v-model="myname"
                id="myname"
                class="text-h3"
                type="text"
                placeholder="..."
              />
            </p>
          </v-col>
        </v-row>
        <div v-if="debugging" class="debugging d-print-none">
          <v-row v-if="debugging" class="d-print-none">
            <v-col
              >filters <br />
              {{ filters }}</v-col
            >
            <v-col>urlparams {{ setUrlParams() }}</v-col>
          </v-row>

          <v-card
            v-for="(li, key) in this.$vuetify.theme.themes"
            :key="key"
            color="success"
          >
            <v-card-title>{{ key }}</v-card-title>
            <v-card-text>
              <v-btn v-for="(color, key) in li" :key="key" :color="color">{{
                key
              }}</v-btn>
            </v-card-text>
          </v-card>
        </div>
        <!-------GRUPPEN ANZEIGE------->
        <v-row dense v-for="gru in itemsGrouped2" :key="gru.Gruppe">
          <v-col>
            <v-card elevation="5" class="sektion" color="success">
              <v-card-title class="text-uppercase text-center">{{
                gru.Gruppe
              }}</v-card-title>
              <v-card-text>
                <!-------SINGLE ITEMS------->
                <v-row>
                  <v-col
                    xs="6"
                    sm="4"
                    md="3"
                    lg="2"
                    class="pa-1 d-print-inline"
                    v-for="item in gru.items"
                    :key="item.was"
                  >
                    <ItemCard
                      :resetter="resetter"
                      :item="item"
                      :weeks="weeks"
                      :wash="wash"
                      :showNumbers="showNumbers"
                      :pictures="pictures"
                    ></ItemCard>
                  </v-col>
                  <div id="add"></div>
                  <v-btn
                    class="pa-2 ma-2 d-print-none"
                    icon
                    color="accent"
                    outlined
                    @click.stop="
                      dialog = true;
                      dialogGruppe = gru.Gruppe;
                    "
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                  <v-row justify="center"> </v-row>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="debugging" class="d-print-none">
          <v-col
            >new item rows<br />
            {{ newItemRows }}</v-col
          >
        </v-row>
        <v-row>
          <v-col v-if="debugging"
            >newItemArray <br />
            {{ newItemArray }}</v-col
          >
        </v-row>
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
          @keyup.enter.prevent="
            dialog = false;
            if (valid) {
              addNewItem(dialogGruppe, newWas);
            }
          "
          @keydown.esc="dialog = false"
          @click:outside="dialog = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline"
                >Noch einzupacken in {{ dialogGruppe }}</span
              >
            </v-card-title>
            <v-card-text color="accent">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      @submit.prevent="
                        dialog = false;
                        if (valid) {
                          addNewItem(dialogGruppe, newWas);
                        }
                      "
                    >
                      <v-text-field
                        ref="newitemText"
                        v-model="newWas"
                        label="Was fehlt?"
                        :rules="textRules"
                        color="warning"
                        required
                        autofocus
                      ></v-text-field
                    ></v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info" class="black--text" @click="dialog = false">
                Abbruch
              </v-btn>
              <v-btn
                color="warning"
                class="white--text"
                :disabled="!valid"
                @click.stop="
                  dialog = false;
                  addNewItem(dialogGruppe, newWas);
                "
              >
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>

      <div class="print">
        <PrintList
          :name="myname"
          :total="items.pagination.total"
          :items="itemsGrouped2"
        /></div
    ></v-main>
  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
var fs = require("fs"); //for testing file existence
import { configuration } from "../plugins/searchconfig.js";
import { rows } from "../../public/static/xls2json.js";
// eslint-disable-next-line no-unused-vars
const singleItem = require("../../public/static/item.json");
import { icons } from "../../public/static/icons.js";
import itemsjs from "itemsjs";
import FilterButton from "@/components/FilterButton";
import ItemCard from "@/components/ItemCard";
import PrintList from "@/components/PrintList";
// import NewItem from "@/components/NewItem";
// import item from "../../public/static/item.json";

export default {
  name: "New",
  // eslint-disable-next-line vue/no-unused-components
  components: { FilterButton, ItemCard, PrintList },
  async created() {
    try {
      const res = await this.axios.get("/static/xls2json.json");

      this.stuff = res;
    } catch (e) {
      console.error(e);
    }
    this.filters = this.initialFilter();
    // this.dataset = itemsjs(this.stuff, this.configuration);
  },
  data() {
    return {
      rows: rows,
      configuration: configuration,
      // making it more generic
      visible: true,
      drawer: true,
      pictures: false,
      showNumbers: false,
      debugging: false,
      // initializing filters with empty arrays
      filters: {},
      weeks: 2,
      wash: 2,
      icons: icons,
      currentParams: window.location.search,
      filters0: null,
      resetter: 0,
      dummy: 0,
      dialog: false,
      dialogGruppe: "",
      textRules: [
        (v) => !!v || "Eingabe notwendig",
        (v) => (v && v.length >= 3) || "midestens 3 Buchstaben",
        (v) => (v && v.length <= 20) || "höchstens 20 Buchstaben",
        (v) => /^[a-zA-ZäÄöÖüÜß ]*$/g.test(v) || "nur Buchstaben!",
      ],
      valid: true,
      newItemArray: [],
      newWas: "",
      id: 1000,
      myname: null,
    };
  },
  methods: {
    reset: function () {
      var filters = {};
      Object.keys(configuration.aggregations).map(function (v) {
        filters[v] = [];
      });
      this.filters = filters;
      this.query = "";
      //clear url, too
      var newURL = location.href.split("?")[0];
      window.history.pushState("object", document.title, newURL);
      //reset items chilc component
      this.resetter++;
    },
    initialFilter() {
      let o = Object.fromEntries(
        new URLSearchParams(location.search.substring(1))
      );
      Object.keys(o).forEach((k) => (o[k] = o[k].split(",")));
      let clean = Object.entries(o).reduce(
        (a, [k, v]) => (v == false ? a : ((a[k] = v), a)),
        {}
      );
      console.log("initial: ", clean);
      return clean;
    },
    setUrlParams() {
      const urlStr = window.location.href;
      const urlObj = new URL(urlStr);
      var newObj = Object.fromEntries(
        Object.entries(this.filters).map(([k, v]) => [k, v.join()])
      );
      urlObj.search = new URLSearchParams(newObj);
      console.log("urlSearchComp: ", urlObj.search);
      history.replaceState({}, "", urlObj.toString());
      return urlObj.search;
    },
    // get list of top tags
    top_tags() {
      return this.dataset.aggregation({
        name: "was",
        per_page: 10,
      });
    },
    getMapFromArray(data) {
      data.reduce((acc, item) => {
        // add object key to our object i.e. charmander: { type: 'water' }
        acc[item.key] = { selected: item.selected };
        return acc;
      }, {});
    },
    compareValues(key, order = "asc") {
      return function innerSort(a, b) {
        if (
          !Object.prototype.hasOwnProperty.call(a, key) ||
          !Object.prototype.hasOwnProperty.call(b, key)
        )
          return 0;
        const comparison = a[key].localeCompare(b[key]);

        return order === "desc" ? comparison * -1 : comparison;
      };
    },
    //build new arra with Gruppe a main object key for easy grouping on page
    reorganizeItems(arr) {
      var keys = { Gruppe: "items" }, // or more if required
        result = [],
        temp = {
          _: result,
        };
      arr.forEach(function (object) {
        Object.keys(keys)
          .reduce(function (level, key) {
            if (!level[object[key]]) {
              level[object[key]] = {
                _: [],
              };
              level._.push({
                [key]: object[key],
                [keys[key]]: level[object[key]]._,
              });
            }
            return level[object[key]];
          }, temp)
          ._.push({
            was: object.was,
            count: object.proWoche,
            fix: object.fix,
          });
      });
      const sortOrder = [
        "PLANUNG",
        "AUSRÜSTUNG",
        "DOKUMENTE",
        "KLEIDUNG",
        "SCHUHE",
      ].map((v) => v.toLowerCase());
      result.sort(function (a, b) {
        console.log("sortdebug", a.Gruppe);
        return (
          sortOrder.indexOf(a.Gruppe.toLowerCase()) -
          sortOrder.indexOf(b.Gruppe.toLowerCase())
        );
      });
      // console.log(result);
      return result;
    },
    printIt() {
      window.print();
    },
    clickMe() {
      console.log("clicked");
    },
    // dialogNewItemClose() {},
    // //set all dialogs to false and the selected to true
    // dialogNewItem(group) {
    //   for (const prop of Object.getOwnPropertyNames(
    //     this.NewItemDialogVisible
    //   )) {
    //     this.NewItemDialogVisible[prop] = false;
    //   }
    //   this.NewItemDialogVisible[group] = true;
    // },
    // addItem() {
    //   this.NewItemDialogVisible = !this.NewItemDialogVisible;
    //   console.log("dialog: ", this.NewItemDialogVisible);
    // },
    addNewItem(group, item) {
      var newItem = {
        proWoche: 0,
        fix: 1,
        mitAusgehen: 1,
        season: ["Sommer", "Winter"],
        transport: ["Auto", "Flug", "Radtour", "Bahn"],
        accommodation: ["Zelt", "Womo", "Fewo", "Hotel"],
        activity: ["Ski", "Wassersport", "Strand", "Gebirge", "Stadt"],
        who: ["Erwachsene", "Kinder", "Baby"],
      };
      newItem.was = item;
      newItem.Gruppe = group;
      this.newItemArray.push(newItem);
      this.$refs.form.reset();
    },
  },
  mounted() {},
  updated() {
    this.setUrlParams();
  },
  computed: {
    dataset() {
      return itemsjs(rows.concat(this.newItemArray), configuration);
    },
    items() {
      // console.log("filters: ", this.filters);
      var result = this.dataset.search({
        sort: "name_asc",
        per_page: 100,
        filters: this.filters,
      });
      return result;
    },
    //experiment to restructure items in groups--------------------------
    itemsGrouped2() {
      return this.reorganizeItems(this.items.data.items);
    },
    //-------------------------------------------------------------------
    groups() {
      // console.log(
      // this.items.data.aggregations["Gruppe"].buckets.map((a) => a.key)
      // );
      return this.items.data.aggregations["Gruppe"].buckets.map((a) => a.key);
    },
    // NewitemDialogVisible() {
    //   var newObj = {};
    //   var arr = this.items.data.aggregations["Gruppe"].buckets.map(
    //     (a) => a.key
    //   );
    //   arr.forEach((key) => (newObj[key] = false));
    //   console.log("visibledialog: ", newObj);
    //   return newObj;
    // },
    itemsGrouped() {
      var g = this.items.data.items.reduce((acc, item) => {
        // add object key to our object i.e. charmander: { type: 'water' }
        acc[item.Gruppe] = { was: item.was };
        return acc;
      }, {});
      return g;
    },
    activeFab() {
      switch (this.drawer) {
        case true:
          return { color: "warning", icon: "mdi-arrow-left" };
        case false:
          return { color: "red", icon: "mdi-backburger" };
        default:
          return {};
      }
    },
  },
  watch: {
    filters: function (val, oldVal) {
      console.log("filter new: %O, old: %O", val, oldVal);
      // this.setUrlParams();
    },
    items: function (val, oldVal) {
      console.log(
        "items new: %O, old: %O",
        val.data.aggregations,
        oldVal.data.aggregations
      );
    },
    itemsGrouped2: function (val, oldVal) {
      console.log("items grouped: %O, old: %O", val, oldVal);
    },
    NewItemDialogVisible: function (val, oldVal) {
      console.log("show dialog new item ", val, oldVal);
    },
  },
};
</script>
<style lang="scss" scoped>
input#myname {
  border: none;
  border-bottom: 1px solid gray;
  background: transparent;
  outline: none;
  max-width: 400px;
}
#btnToggleDrawer {
  z-index: 9999 !important;
}

.height48 {
  height: 47px !important;
}
.item {
  min-width: 187px;
}
#main {
  background: url("../../public/static/packed.jpg") no-repeat center center
    fixed !important;
  background-size: cover;
}
.v-input {
  color: black;
  caret-color: green;
}
.v-application .error--text {
  color: #b70d0d !important;
  caret-color: var(--v-error-base) !important;
}
@media print {
  .v-main {
    padding: 0 !important;
  }
  .container {
    margin: 0 !important;
  }
  * {
    transition: none !important;
  }
}
</style>