import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: { customProperties: true },
    dark: false,
    disable: false,
    themes: {
      dark: {
        primary: colors.lime.lighten5,
        accent: '#219ebc',
        secondary: colors.lightBlue.lighten4,
        success: '#ffb703',
        info: '#fb8500',
        warning: '#FB8C00',
        error: '#FF5252'
      },
      light: {
        primary: '#f0f4c3',
        accent: '#bdc192',
        secondary: '#b3e5fc',
        success: '#fffff6',
        info: '#e6ffff',
        warning: '#82b3c9',
        error: '#9692c1'
      }
    }
  }

});
