<template>
  <v-card
    :color="changeBackgroundColor ? 'accent' : 'success'"
    @click="changeBackgroundColor = !changeBackgroundColor"
    ><v-card-text
      elevation="5"
      class="ma-1 pa-2 text-body-1 item"
      :class="changeBackgroundColor ? 'black--text' : 'grey--text'"
    >
      <span v-if="showNumbers">{{ counter(item) }}</span>
      {{ item.was }}
      <Picture v-if="pictures" :pic="item.was"></Picture></v-card-text
  ></v-card>
</template>


<script>
import Picture from "@/components/Picture";
export default {
  name: "ItemCard",
  components: { Picture },
  props: {
    pictures: { type: Boolean, required: true },
    showNumbers: { type: Boolean, required: true },
    item: { type: Object, required: true },
    weeks: { type: Number, required: true },
    wash: { type: Number, required: true },
    resetter: { type: Number, required: true },
  },
  data() {
    return { changeBackgroundColor: true };
  },
  methods: {
    counter(item) {
      if (item.fix > 0) return "";
      return Math.ceil(item.count * Math.min(this.weeks, this.wash));
    },
    reset() {
      this.changeBackgroundColor = true;
    },
  },
  watch: {
    resetter: function () {
      this.reset();
    },
  },
};
</script>

<style>
</style>