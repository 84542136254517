export var rows = [
  {
    "was": "Abfahrtsschuhe",
    "Gruppe": "Kleidung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Abfahrtsski",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Anzahlung Hotel",
    "Gruppe": "Planung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Aussies",
    "Gruppe": "Schuhe",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Ausweis",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Autohalterung",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto"
    ],
    "accommodation": [
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Autoladekabel",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto"
    ],
    "accommodation": [
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Autoreservierung",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug"
    ],
    "accommodation": [
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Badehose",
    "Gruppe": "Kleidung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Wassersport",
      "Strand"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Bargeld",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Besteck",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Blumen gie\u00dfen",
    "Gruppe": "Planung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Brennstoff",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Briefkasten leeren",
    "Gruppe": "Planung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Brillenetui",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "B\u00fccher",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Devisen",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Drachen",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 1,
    "season": [
      "Sommer"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Kinder"
    ]
  },
  {
    "was": "Ersatzakkus",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Erste-Hilfe-Set",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Feuerzeug",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Flugticket",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Flug"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Flugzeugbrille",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Flug"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Fotoapparat",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "F\u00fchrerschein",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug"
    ],
    "accommodation": [
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Geschirr",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Handschuhe",
    "Gruppe": "Kleidung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Gebirge"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Handtuch",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 2,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Handy",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Handy roaming",
    "Gruppe": "Planung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Hausschuhe",
    "Gruppe": "Schuhe",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Fewo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Haustier versorgen",
    "Gruppe": "Planung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Heizung auf Urlaub",
    "Gruppe": "Planung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Hemden",
    "Gruppe": "Kleidung",
    "proWoche": 3,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Hose, fein",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 1,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Hose, leicht",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "int. F\u00fchrerschein",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug"
    ],
    "accommodation": [
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Isomatte",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Jackett",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Klopapier",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Kocher",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Kreditkarte",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Kuscheltiere",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Kinder"
    ]
  },
  {
    "was": "Landkarten",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Radtour"
    ],
    "accommodation": [
      "Zelt",
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "LL-Schuhe",
    "Gruppe": "Schuhe",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "LL-Ski",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Medizin",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Messer, gro\u00df",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "M\u00fcffchen",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "M\u00fcll leeren",
    "Gruppe": "Planung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "M\u00fcllt\u00fcten",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "M\u00fctzen",
    "Gruppe": "Kleidung",
    "proWoche": 2,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Gebirge"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Oma",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Portemonnaie 2",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Pullover",
    "Gruppe": "Kleidung",
    "proWoche": 2,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Gebirge"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Radhose, lang",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Radtour"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Regenhose",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Radtour"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Gebirge"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Regenjacke",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Radtour"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Gebirge"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Reisef\u00fchrer",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Reisepass",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Sandalen",
    "Gruppe": "Schuhe",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Sandschaufel",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Strand"
    ],
    "who": [
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Schal",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Schlafanzug",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Schlafsack",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Schwimmfl\u00fcgel etc.",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Wassersport",
      "Strand"
    ],
    "who": [
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Schwimmwesten",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Wassersport"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Shorts",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Skat",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Skihose",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Socken",
    "Gruppe": "Kleidung",
    "proWoche": 5,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Socken, dick",
    "Gruppe": "Kleidung",
    "proWoche": 2,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Gebirge"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Sonnenbrille",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Sonnencreme",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Sonnenhut",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Spiele",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Sp\u00fclmittel",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "T-Shirts",
    "Gruppe": "Kleidung",
    "proWoche": 6,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Tagesrucksack",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Taschenlampe",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Taschenmesser",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Topfkratzer",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Tourenski",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Trinkflasche",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "U-Hosen",
    "Gruppe": "Kleidung",
    "proWoche": 7,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Visum",
    "Gruppe": "Dokumente",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Wachs",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Wanderschuhe",
    "Gruppe": "Schuhe",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Gebirge"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Wandersocken",
    "Gruppe": "Kleidung",
    "proWoche": 2,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Gebirge"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Wanderst\u00f6cke",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "W\u00e4rmflasche",
    "Gruppe": "Kleidung",
    "proWoche": 1,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Waschzeug",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Wasserfilter",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Wassersack",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Radtour"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Windeln",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 35,
    "fix": 0,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Baby"
    ]
  },
  {
    "was": "Zahnb\u00fcrste",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Zahnpasta",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder",
      "Baby"
    ]
  },
  {
    "was": "Zeitschriften",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene",
      "Kinder"
    ]
  },
  {
    "was": "Zeitung umleiten",
    "Gruppe": "Planung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Zelt",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Zeltunterlage",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Strand",
      "Gebirge",
      "Stadt"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "GPS-Ger\u00e4t",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Auto",
      "Flug",
      "Radtour",
      "Bahn"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Gebirge"
    ],
    "who": [
      "Erwachsene"
    ]
  },
  {
    "was": "Radtaschen",
    "Gruppe": "Ausr\u00fcstung",
    "proWoche": 0,
    "fix": 1,
    "mitAusgehen": 0,
    "season": [
      "Sommer",
      "Winter"
    ],
    "transport": [
      "Radtour"
    ],
    "accommodation": [
      "Zelt",
      "Womo",
      "Fewo",
      "Hotel"
    ],
    "activity": [
      "Ski",
      "Wassersport",
      "Gebirge"
    ],
    "who": [
      "Erwachsene"
    ]
  }
]