export var icons = {
  "Sommer": "mdi-weather-sunny",
  "Winter": "mdi-snowflake",
  "Auto": "mdi-car",
  "Bahn": "mdi-train",
  "Flug": "mdi-airplane",
  "Radtour": "mdi-bike",
  "Fewo": "mdi-home",
  "Hotel": "mdi-bed",
  "Womo": "mdi-rv-truck",
  "Zelt": "mdi-tent",
  "Erwachsene": "mdi-human-male-female",
  "Kinder": "mdi-human-child",
  "Baby": "mdi-baby",
  "Ausrüstung": "mdi-tennis",
  "Dokumente": "mdi-file-document-multiple",
  "Kleidung": "mdi-tshirt-crew",
  "Schuhe": "mdi-shoe-formal",
  "Planung": "mdi-graph",
  "Gebirge": "mdi-image-filter-hdr",
  "Wassersport": "mdi-sail-boat",
  "Ski": "mdi-ski",
  "Stadt": "mdi-city",
  "Strand": "mdi-beach"

}