<template>
  <v-tooltip v-model="show" top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :value="bucket.key"
        color="accent"
        rounded
        large
        class="text-caption black--text mr-0 filter-button"
        ><v-icon left large dark>{{ icon }}</v-icon> ({{ bucket.doc_count }})
      </v-btn>
    </template>
    <span>{{ bucket.key }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "FilterButton",
  props: {
    bucket: null,
    icon: null,
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped>
.filter-button {
  min-width: 78px !important;
}
</style>